<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <DataTable
        :value="customers"
        :lazy="true"
        :paginator="true"
        class="p-datatable-customers"
        :rows="per_page"
        :totalRecords="totalRows"
        @page="onPage($event)"
        @sort="onSort($event)"
        dataKey="id"
        :rowHover="true"
        :filters="filters"
        :loading="loading"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 30]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        <template #header>
          <div class="table-header">
            List of Comissions Fee
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <Dropdown
                v-model="filters.duration"
                :options="duration"
                @change="topFilter($event.value, 'duration')"
                placeholder="Select a type"
                class="p-column-filter"
                :showClear="true"
              >
                <template #option="slotProps">
                  <span :class="'customer-badge status-' + slotProps.option">{{
                    slotProps.option
                  }}</span>
                </template>
              </Dropdown>
              <!-- <InputText v-model="filters.global"  @keydown.enter="onFilter($event)" placeholder="Global Search" /> -->
            </span>
          </div>
        </template>
        <template #empty> No record found. </template>
        <template #loading> Loading data. Please wait. </template>
        <!-- <Column selectionMode="multiple" headerStyle="width: 3em"></Column> -->
        <Column
          header="Name"
          :sortable="true"
          sortField="name"
          filterField="name"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Name</span>

            {{ slotProps.data.order.user.name }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.name"
              @keydown.enter="topFilter(filters.name, 'name')"
              class="p-column-filter"
              placeholder="Search by name"
            />
          </template>
        </Column>

        <Column
          header="Email"
          :sortable="true"
          sortField="email"
          filterField="email"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Email</span>

            {{ slotProps.data.order.user.email }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.email"
              @keydown.enter="topFilter(filters.email, 'email')"
              class="p-column-filter"
              placeholder="Search by Email"
            />
          </template>
        </Column>
        <Column field="type" header="Type" :sortable="true">
          <template #body="slotProps">
            <span class="p-column-title">Type</span>
            {{ slotProps.data.order.order_type }}
          </template>
          <template #filter>
            <Dropdown
              v-model="filters.type"
              @change="topFilter($event.value, 'type')"
              :options="types"
              placeholder="Select a type"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <span :class="'customer-badge status-' + slotProps.option">{{
                  slotProps.option
                }}</span>
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column
          header="Comission Currency"
          :sortable="true"
          sortField="commission_currency"
          filterField="commission_currency"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Currency</span>

            {{ slotProps.data.commission_currency }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.commission_currency"
              @keydown.enter="
                topFilter(filters.commission_currency, 'commission_currency')
              "
              class="p-column-filter"
              placeholder="Search by currency"
            />
          </template>
        </Column>
        <Column
          header="Commission"
          :sortable="true"
          sortField="commission"
          filterField="commission"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Commission</span>

            {{ slotProps.data.commission }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.commission"
              @keydown.enter="topFilter(filters.commission, 'commission')"
              class="p-column-filter"
              placeholder="Search by commission"
            />
          </template>
        </Column>
        <Column
          header="Date"
          :sortable="true"
          sortField="created_at"
          filterField="created_at"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Created At</span>
            <span
              :class="'customer-badge status-' + slotProps.data.created_at"
              >{{
                new Date(slotProps.data.created_at).toLocaleDateString("en-US")
              }}</span
            >
          </template>
          <template #filter>
            <Calendar
              v-model="filters.date"
              :showButtonBar="true"
              dateFormat="yy-mm-dd"
              class="p-column-filter"
              @date-select="topFilter(filters.date, 'date')"
              placeholder="Transaction Date"
            />
          </template>
        </Column>
      </DataTable>

      <!-- <div class="input-box" id="total">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 mx-auto">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  :value="total_commission.toFixed(5)"
                  readonly
                  disabled
                />
              </div>
            </div> -->
            <!--col-xl-4 col-lg-4 col-md-4 mx-auto-->
          <!-- </div>
        </div>
      </div> -->
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import ApiClass from "../../../api/api";

import PageHeader from "@/components/page-header";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
// import ColumnGroup from 'primevue/columngroup';
import InputText from "primevue/inputtext";
// import MultiSelect from 'primevue/multiselect';
import Dropdown from "primevue/dropdown";

import Calendar from "primevue/calendar";
// import ProgressBar from 'primevue/progressbar';

export default {
  components: {
    Layout,
    PageHeader,
    DataTable,
    Dropdown,
    Calendar,
    Column,
    InputText,
  },
  data() {
    return {
      totalRows: 1,
      per_page: 10,
      page: 1,
      title: "Fee Report",
      items: [
        {
          text: "Commission Fees",
          href: "#",
        },
        {
          text: "Report",
          active: true,
        },
      ],
      filters: {
        name: null,
        type: null,
        commission_currency: null,
        commission: null,
        date: null,
        duration: null,
      },
      filter: "",
      customers: [],
      selectedCustomers: null,
      loading: true,
      statuses: ["completed", "pending"],
      types: ["buy", "sell"],
      duration: ["day", "week", "month"],
      total_commission: null,
    };
  },
  created() {},
  mounted() {
    this.tradeFee();
  },
  methods: {
    async tradeFee() {
      await ApiClass.getRequest(
        "admin/report/trade?per_page=" +
          this.per_page +
          "&page=" +
          this.page +
          "&" +
          this.filter
      ).then((res) => {
        if (res.data.status_code == 1) {
          this.loading = false;
          this.customers = res.data.data.data;

          this.currentPage = res.data.data.current_page;
          this.totalRows = res.data.data.total;
          this.total_commission = res.data.data.total_commission;
        }
      });
    },
    onPage(event) {
      if (this.page != event.page + 1) {
        this.loading = !this.loading;
        this.page = event.page + 1;
      }
      if (this.per_page != event.rows) {
        this.loading = !this.loading;
        this.per_page = event.rows;
      }

      this.tradeFee();
    },
    onSort(event) {
      this.loading = !this.loading;
      var sortby = event.sortOrder == 1 ? "asc" : "desc";
      this.filter = "sortby=" + sortby + "&sortbyname=" + event.sortField;
      this.tradeFee();
    },
    onFilter() {},

    topFilter(columnVal, columnName) {
      if (columnName == "date") {
        // columnVal =columnVal.toISOString().split('T')[0];
        columnVal = columnVal.toLocaleDateString("es-US").split("T")[0];
        columnVal = columnVal.split("/").reverse().join("-");
      }
      this.loading = !this.loading;

      this.$set(this.filters, columnName, columnVal);

      var x = this.filters;
      const resulted = JSON.parse(JSON.stringify(x), (key, value) =>
        value === null || value === "" ? undefined : value
      );

      let query = new URLSearchParams(resulted).toString();

      this.filter = query;

      this.tradeFee();
    },
  },
};
</script>
<style lang="scss">
.p-paginator {
  .p-paginator-current {
    margin-left: auto;
  }
}

.p-progressbar {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #00acad;
  }
}

.table-header {
  display: flex;
  justify-content: space-between;
}

.p-datepicker {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

.p-datatable.p-datatable-customers {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
.p-calendar .p-datepicker {
  width: 350px;
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 64em) {
  .p-datatable {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4rem 1rem -0.4rem -0.4rem;
          font-weight: bold;
        }
      }
    }
  }
}

#total input.form-control {
  background: #ffffff;
  padding: 0.5rem 0.5rem;
  border: 1px solid #ced4da;
  max-width: 189px;
  margin-left: auto;
}
</style>
